import { path } from "chromedriver";
import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Welcome = () => import("@/views/pages/Welcome");
const UserIndex = () => import("@/views/pages/user/Index");
const UserDetails = () => import("@/views/pages/user/Details");
const ReportIndex = () => import("@/views/pages/report/Index");
const AdminIndex = () => import("@/views/pages/admin/Index");
const AdminDetails = () => import("@/views/pages/admin/Details");
const PaymentChannelIndex = () => import("@/views/pages/paymentchannel/Index");
const BankIndex = () => import("@/views/pages/bank/Index");
const BankDetails = () => import("@/views/pages/bank/Details");
const DocumentIndex = () => import("@/views/pages/document/Index");
const DocumentDetails = () => import("@/views/pages/document/Details");
const FeedbackIndex = () => import("@/views/pages/feedback/Index");
const FeedbackDetails = () => import("@/views/pages/feedback/Details");
const FeedbackEditFeedbackQuestion = () =>
  import("@/views/pages/feedback/EditFeedbackQuestion");
const NotificationSettings = () =>
  import("@/views/pages/notification/Settings");
const NotificationPoint = () => import("@/views/pages/notification/Point");
const PromotionIndex = () => import("@/views/pages/promotion/Index");
const LineMessageIndex = () => import("@/views/pages/linemessage/Index");
const LineMessageDetails = () => import("@/views/pages/linemessage/Details");
const Redirection = () => import("@/views/pages/redirection/Index");
const Termsandconditions = () =>
  import("@/views/pages/termsandconditions/Index");
const MapProductList = () => import("@/views/pages/map/ProductList");
const ReportNoteCodeIndex = () => import("@/views/pages/reportnotecode/Index");
const NotificationNoteCodeIndex = () =>
  import("@/views/pages/notificationnotecode/Index");
const AuditIndex = () => import("@/views/pages/audit/Index");
const AuditDetails = () => import("@/views/pages/audit/Details");
const Dashboard = () => import("@/views/pages/dashboard/Index");
const RichMenu = () => import("@/views/pages/richMenu/Index");
const RichMenuDetail = () => import("@/views/pages/richMenu/Detail");

const Page403 = () => import("@/views/pages/Page403");
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");

Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresIsLogin: true },
    },
    {
      path: "/azureauth/login",
      name: "Azure Auth callback",
      component: () => import("@/views/pages/AzureAuthLogin"),
      meta: { requiresIsLogin: true },
    },
    {
      path: "/",
      name: "Home",
      component: TheContainer,
      meta: { requiresAuth: true },
      children: [
        {
          path: "/",
          name: "Welcome",
          component: Welcome,
          meta: { requiresAuth: true },
        },
        {
          path: "/user",
          name: "User List",
          component: UserIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "user/details/:id",
          name: "User Details",
          component: UserDetails,
          meta: { requiresAuth: true },
        },
        {
          path: "report/:id",
          name: "Report",
          component: ReportIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "admin",
          name: "Admin List",
          component: AdminIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "admin/details/:id",
          name: "Admin Details",
          component: AdminDetails,
          meta: { requiresAuth: true },
        },
        {
          path: "/paymentchannel",
          name: "Payment Channel",
          component: PaymentChannelIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "/bank",
          name: "Bank List",
          component: BankIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "bank/details/:id",
          name: "Bank Details",
          component: BankDetails,
          meta: { requiresAuth: true },
        },
        {
          path: "/document",
          name: "Upload Document",
          component: DocumentIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "/document/details/:id",
          name: "File Maneger",
          component: DocumentDetails,
          meta: { requiresAuth: true },
        },
        {
          path: "/feedback",
          name: "Upload Document",
          component: FeedbackIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "/feedback/details/:id",
          name: "Complaint",
          component: FeedbackDetails,
          meta: { requiresAuth: true },
        },
        {
          path: "/feedback/editfeedbackquestion",
          name: "Edit Question/Answer",
          component: FeedbackEditFeedbackQuestion,
          meta: { requiresAuth: true },
        },
        {
          path: "/notification/settings",
          name: "Notification Settings",
          component: NotificationSettings,
          meta: { requiresAuth: true },
        },
        {
          path: "/notification/point",
          name: "Notification Point",
          component: NotificationPoint,
          meta: { requiresAuth: true },
        },
        {
          path: "/promotion",
          name: "Promotion",
          component: PromotionIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "/linemessage",
          name: "Line Message Management",
          component: LineMessageIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "/linemessage/:id",
          name: "Line Message Details",
          component: LineMessageDetails,
          meta: { requiresAuth: true },
        },
        {
          path: "/urlredirection",
          name: "Redirection",
          component: Redirection,
          meta: { requiresAuth: true },
        },
        {
          path: "/termsandconditions",
          name: "Terms & Conditions",
          component: Termsandconditions,
          meta: { requiresAuth: true },
        },
        {
          path: "/map/productlist",
          name: "Map - Product List",
          component: MapProductList,
          meta: { requiresAuth: true },
        },
        {
          path: "/notecodereport",
          name: "Report Note Code",
          component: ReportNoteCodeIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "/notificationnotecode",
          name: "Notification Note Code",
          component: NotificationNoteCodeIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "/audit",
          name: "Audit List",
          component: AuditIndex,
          meta: { requiresAuth: true },
        },
        {
          path: "audit/details/:id",
          name: "Audit Details",
          component: AuditDetails,
          meta: { requiresAuth: true },
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: { requiresAuth: true },
        },
        {
          path: "/rich-menu",
          name: "Line Rich Menu",
          component: RichMenu,
          meta: { requiresAuth: true },
    
        },
        {
          path: "/rich-menu/:id", 
          name: "Line Rich Menu Detail",
          component: RichMenuDetail,
          meta: { requiresAuth: true },
        },
     
        // {
        //   path: '/',
        //   redirect: '/',
        //   name: 'Theme',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: '/',
        //       name: 'Home',
        //       component: Home
        //     },
        //   ]
        // },
        {
          path: "403",
          name: "Page403",
          component: Page403,
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
        {
          path: "*",
          name: "Page-404",
          component: Page404,
        },
      ],
    },
  ];
}
